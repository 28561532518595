import React from 'react';

import MojaSlika from '../imgs/VladaNEW.jpg';

const VladaBIO = (props) => {
    const ime = props.lang === 'rs' ? 'ВЛАДИМИР' : 'VLADIMIR';
    return (
        <div className='text-center order-1 order-md-1 ml-lg-2 mb-4'>
            <p>{ime}</p>
            <img alt="Vladimir" src={MojaSlika} className="slikaProfila" />
        </div>
    );

};

export default VladaBIO;
import React, { Component } from 'react';
import {
    NavLink,
} from 'react-router-dom';

class Navig extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.klikMeni=()=>{
            if(window.screen.width<768){
                document.getElementsByClassName('navbar-toggler')[0].click();
            }          
        };
    }

    render() {

        var meni = this.props.lang === 'rs' ? '' : '/eng';
        var jezik = this.props.lang === 'rs' ?
            {
                home: 'ДОБРОДОШЛИ',
                vlada: 'ВЛАДИМИР',
                tanja: 'ТАТЈАНА',
                projekti: 'ПРОЈЕКТИ',
                kontakt: 'КОНТАКТ'
            }
            :
            {
                home: 'WELCOME',
                vlada: 'VLADIMIR',
                tanja: 'TATYANA',
                projekti: 'PROJECTS',
                kontakt: 'CONTACT'
            };
        var link = this.props.lang === 'rs' ?
            {
                home: '',
                vlada: 'vladimir',
                tanja: 'tatjana',
                projekti: 'projekti',
                kontakt: 'kontakt'
            }
            :
            {
                home: '',
                vlada: 'vladimir',
                tanja: 'tatyana',
                projekti: 'projects',
                kontakt: 'contact'
            };
        return (
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li onClick={this.klikMeni} className="nav-item">
                        <NavLink activeClassName="active-main" className="nav-link nav-main" to={`${meni}`}>{jezik.home}</NavLink>
                    </li>
                    <li onClick={this.klikMeni} className="nav-item">
                        <NavLink activeClassName="active-lang" className="nav-link nav-main" to={`${meni}/${link.vlada}`}>{jezik.vlada}</NavLink>
                    </li>
                    <li onClick={this.klikMeni} className="nav-item">
                        <NavLink activeClassName="active-lang" className="nav-link nav-main" to={`${meni}/${link.tanja}`}>{jezik.tanja}</NavLink>
                    </li>
                    <li onClick={this.klikMeni} className="nav-item">
                        <NavLink activeClassName="active-lang" className="nav-link nav-main" to={`${meni}/${link.projekti}`}>{jezik.projekti}</NavLink>
                    </li>
                    <li onClick={this.klikMeni} className="nav-item">
                        <NavLink activeClassName="active-lang" className="nav-link nav-main" to={`${meni}/${link.kontakt}`}>{jezik.kontakt}</NavLink>
                    </li>
                </ul>
            </div>

        );
    }
}

export default Navig;
/* eslint-disable linebreak-style */
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Like } from 'react-facebook';

import Imenik from '../files/Imenik.zip';

class ProjektiENG extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Projects</title>
            </Helmet>
            , document.getElementById('langTag'));  
    }
    componentDidUpdate(){
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Projects</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render(){
        return (          
            <div className="col-12 mx-auto col-md-8 offset-md-4 col-lg-12 offset-lg-0">
                <div className="col-12 mx-auto">          
                    <h1 className="text-center mt-5 mt-lg-0">PROJECTS</h1>
                    <p id='WWImenik' className='pt-3 mx-3 font-weight-bold'>WW Imenik .NET</p>
                    <p>A very functional directory for everyday use. The program can be <a href={Imenik}>downloaded here</a>. It is essential to have .NET 4.0 previously installed. <a target='_blank' rel="noopener noreferrer" href='https://milivoyevich.visualstudio.com/Adresar'>Further details...</a></p>
                    <Like width='280' href="http://миливојевић.срб/енг/Пројекти/#WWImenik" colorScheme="dark" share />
                </div>
            </div>         
        );
    }
}
export default ProjektiENG;
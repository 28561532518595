import React from 'react';

import MojaSlika from '../imgs/TanjaNEW.jpg';

const TanjaBIO = (props) => {
    const ime = props.lang === 'rs' ? 'ТАТЈАНА ДРАГИЊА' : 'TATYANA DRAGINYA';
    return (
        <div className='text-center order-2 order-lg-3  ml-md-0 mb-4'>
            <p>{ime}</p>
            <img alt="Tatyana" src={MojaSlika} className="slikaProfila ml-lg-5 mr-lg-5" />
        </div>
    );
};

export default TanjaBIO;
/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import KontaktForma from '../elementi/kontaktForma';

class Kontakt extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Контакт</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate() {
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Контакт</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render() {
        return (
            <div className="col-12 mx-auto col-md-8 offset-md-4 col-lg-12 offset-lg-0">
                <h1 className="text-center mt-5 mt-lg-0">КОНТАКТ</h1>
                <p className='text-justify pt-3 mx-3'>
                    Можете нас контактирати на следеће адресе е-поште:<br/>
                    <a href='mailto:milivoyevich@gmail.com'>milivoyevich@gmail.com</a> (препоручена адреса)<br/>
                    <a href='mailto:milivoyevich@yahoo.com'>milivoyevich@yahoo.com</a><br/>
                    <a href='mailto:draginya.tatyana@hotmail.com'>draginya.tatyana@hotmail.com</a><br/>
                    као и на број: <a href='tel:+381642113258'>+381(0)64/211-3258</a>.
                </p>
                <KontaktForma lang='rs'>

                </KontaktForma>
            </div>
        );
    }
}
export default Kontakt;
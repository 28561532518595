/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import MiSlika from '../imgs/mi_2018avg.jpg';

class Home extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Добродошли</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate() {
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Добродошли</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render() {
        return (
            <div className="col-12 mx-auto">
                <h1 className="text-center mt-5 mt-lg-0">ДОБРОДОШЛИЦА</h1>
                <p className="text-justify pt-3 mx-3">Налазите се на породичном сајту Миливојевића. Ово је радна поставка и има за циљ да прикаже наше могућности и достигнућа. Поставка је под брендом Сокоћало by Willy Wolf, који ће надамо се, ускоро прерасти и у малу породичну фирму. Приказаћемо вам историјат послова којима смо се бавили и којима се бавимо, као и неке пројекте које смо урадили. Поделићемо са вама неке готове програме и идеје у циљу размене знања и опште добробити.</p>
                <img className='mx-3 mislika' width='95%' alt='Ми, авг 2018' src={MiSlika} />
            </div>
        );
    }
}
export default Home;
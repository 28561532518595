import React from 'react';


import CvInfoENG from '../files/Vlada/Vlada-Info-CV-ENG.pdf';
import CvInfoSRB from '../files/Vlada/Vlada-Info-CV-SRB.pdf';
import CvEuENG from '../files/Vlada/CV-EU-Vlada-ENG.pdf';
import CvEuSRB from '../files/Vlada/CV-EU-Vlada-SRB.pdf';

const VladaInfo = (props) => {
    const ime = props.lang === 'rs' ? 'ВЛАДИМИР - ПОДАЦИ' : 'VLADIMIR - INFO';
    const tekst = props.lang === 'rs' ?
        <p>Рођен у Шапцу, Србија,
        20. фебруара 1967.</p>
        :
        <p>Born in Šabac, Serbia,
        20th February 1967</p>;
    const linkovi = props.lang === 'rs' ?
        <p>
            <a target='_blank' rel="noopener noreferrer" href={CvInfoSRB} className="">CV српски Info</a><br />
            <a target='_blank' rel="noopener noreferrer" href={CvEuSRB} className="">CV српски EU</a>
        </p>
        :
        <p>
            <a target='_blank' rel="noopener noreferrer" href={CvInfoENG} className="">CV English Info</a><br />
            <a target='_blank' rel="noopener noreferrer" href={CvEuENG} className="">CV English EU</a>
        </p>;
    return (
        <div className='side text-left order-5 order-lg-5 ml-lg-3 pl-2 ml-lg-0'>
            <p className='text-center'>{ime}</p>
            {tekst}
            {linkovi}
        </div>
    );

};

export default VladaInfo;
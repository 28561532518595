import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class ContactForma extends Component {
    render() {
        this.polja = {};
        this.poslato='';
     
        switch (this.props.lang) {
        case 'rs': 
            this.polja={
                title:'Контакт форма',
                required: 'Обавезно поље',
                submit: 'Пошаљи',
                reset: 'Одбаци',
                imepre:{
                    label:'Име и презиме:',
                    placeholder:'Унесите ваше име'
                },
                email:{
                    label:'Е-пошта:',
                    placeholder:'Унесите адресу ваше е-поште',
                    error:'адреса е-поште мора бити ваљана'
                },
                tekst:{
                    label:'Порука:',
                    placeholder:'Унесите текст поруке'
                },
                success: 'Упсешно послато!',
                error: 'Грешка при слању!'
            };
            break;
        case 'en': 
        default: 
            this.polja={
                title:'Contact Form',
                required: 'Required field',
                submit: 'Submit',
                reset: 'Reset',
                imepre:{
                    label:'Full Name:',
                    placeholder:'Enter your name'
                },
                email:{
                    label:'Email:',
                    placeholder:'Enter your email',
                    error:'email must be a valid email'
                },
                tekst:{
                    label:'Message:',
                    placeholder:'Enter your text'
                },
                success: 'Success!',
                error: 'Error!'
            };
            break;
        }
        var polja = this.polja;
        return (
            <Formik               
                initialValues={{ imepre: '', email: '', tekst: '' }}
                onReset={()=>{
                    document.getElementById('poslato').innerHTML='';
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        var body = new FormData();
                        body.append('forma',JSON.stringify(values));
                        fetch('/server/sendMailService.php', //http://xn--b1aaikadqgw4xib.xn--90a3ac
                            {
                                method: 'POST',
                                body: body
                                // headers: {
                                //     //'Content-Type': 'application/json'
                                //     'Content-Type': 'application/x-www-form-urlencoded'
                                // }
                            })
                            .then(function (res) { return res.json(); })
                            .then(function (data) {
                                //window.console.log(data);                              
                                document.getElementById('poslato').innerHTML= polja.success;
                            })
                            .catch(error=>{
                                //window.console.log(error);                             
                                document.getElementById('poslato').innerHTML= polja.error;
                            });                 
                        //window.console.log(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 500);
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email(this.polja.email.error).required(this.polja.required),
                    imepre: Yup.string().required(this.polja.required),
                    tekst: Yup.string().required(this.polja.required)
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                    } = props;

                    return (

                        <Form onSubmit={handleSubmit} className='shirForme'>
                            <fieldset className='mx-3 my-4'>
                                <legend>
                                    <u>{this.polja.title}</u>
                                </legend>
                                <label htmlFor="imepre" style={{ display: 'block' }}>
                                    {this.polja.imepre.label}
                                </label>
                                <Field
                                    required
                                    id="imepre"
                                    placeholder={this.polja.imepre.placeholder}
                                    type="text"
                                    value={values.imepre}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.imepre && touched.imepre ? 'text-input form-control error w-100' : 'text-input form-control w-100'
                                    }
                                />
                                {/* {errors.imepre &&
                                    touched.imepre && <div className="input-feedback text-danger">{errors.imepre}</div>} */}
                                <ErrorMessage className="input-feedback text-danger" name="imepre" component="div" />
                                <label className='mt-3' htmlFor="email" style={{ display: 'block' }}>
                                    {this.polja.email.label}
                                </label>
                                <Field
                                    required
                                    id="email"
                                    placeholder={this.polja.email.placeholder}
                                    type="text"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.email && touched.email ? 'text-input form-control error w-100' : 'text-input form-control w-100'
                                    }
                                />
                                {/* {errors.email &&
                                    touched.email && <div className="input-feedback text-danger">{errors.email}</div>} */}
                                <ErrorMessage className="input-feedback text-danger" name="email" component="div" />
                                <label className='mt-3' htmlFor="tekst" style={{ display: 'block' }}>
                                    {this.polja.tekst.label}
                                </label>
                                <textarea
                                    rows='5'
                                    required
                                    id="tekst"
                                    placeholder={this.polja.tekst.placeholder}
                                    type="text"
                                    value={values.tekst}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.tekst && touched.tekst ? 'text-input form-control error w-100' : 'text-input form-control w-100'
                                    }
                                />
                                {/* {errors.tekst &&
                                    touched.tekst && <div className="input-feedback text-danger">{errors.tekst}</div>} */}
                                <ErrorMessage className="input-feedback text-danger" name="tekst" component="div" />
                            </fieldset>
                            <button
                                type="button"
                                className="btn btn-outline-dark outline float-right mx-3 mb-3"
                                onClick={handleReset}
                                disabled={!dirty || isSubmitting}
                            >
                                {this.polja.reset}
                            </button>
                            <button className="btn btn-outline-light outline float-right" 
                                type="submit" disabled={isSubmitting}>
                                {this.polja.submit}
                            </button>   
                            <label id='poslato' className='mx-3 float-left font-weight-bold'></label>            
                            {/* <DisplayFormikState {...props} /> */}
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default ContactForma;
import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import { Helmet } from 'react-helmet';
import './css/site.css';
import logo from './imgs/LogoAnim.gif';
import {
    //BrowserRouter as Router,
    Route,
    NavLink,
    Switch
    /*Redirect*/
} from 'react-router-dom';
import {FacebookProvider} from 'react-facebook';

import Navig from './navigate';
import Home from './sr/home';
import Tanja from './sr/tanja';
import Vlada from './sr/vlada';
import Projekti from './sr/projekti';
import Kontakt from './sr/kontakt';
import HomeEng from './en/homeENG';
import TanjaENG from './en/tanjaENG';
import VladaENG from './en/vladaENG';
import ProjektiENG from './en/projektiENG';
import KontaktENG from './en/kontaktENG';

import VladaBIO from './elementi/vladaBIO';
import TanjaBIO from './elementi/tanjaBIO';
import TanjaInfo from './elementi/tanjaInfo';
import VladaInfo from './elementi/vladaInfo';
import VladaLinks from './elementi/vladaLinks';
import TanjaLinks from './elementi/tanjaLinks';

class Site extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'rs',
            srlink: '/',
            enlink: '/eng',
            currentUrl: ''
        };
        this.apdejt=false;
        this.ruteri='';
    }

    convertLink(lang,link){
        if(lang=='en'){
            if(link=='tatjana') return 'tatyana';
            if(link=='projekti') return 'projects';
            if(link=='kontakt') return 'contact';
        }
        if(lang=='rs'){
            if(link=='tatyana') return 'tatjana';
            if(link=='projects') return 'projekti';
            if(link=='contact') return 'kontakt';
        }
        return link;
    }
    defineURL(url_niz) {
        if (url_niz.length > 3) {
            switch (url_niz[3]) {
            case 'eng':
            case encodeURIComponent('eng'):
                if (url_niz.length > 4) {
                    this.setState({
                        lang: 'en',
                        srlink: '/' + this.convertLink('rs', url_niz[4]),
                        enlink: '/eng/' + this.convertLink('en', url_niz[4]),
                        currentUrl: window.location.href
                    });
                }
                else {
                    this.setState({
                        lang: 'en',
                        srlink: '/',
                        enlink: '/eng',
                        currentUrl: window.location.href
                    });
                }
                break;
            case '':
                this.setState({
                    lang: 'rs',
                    srlink: '/',
                    enlink: '/eng',
                    currentUrl: window.location.href
                });
                break;
            default:
                this.setState({
                    lang: 'rs',
                    srlink: '/' + this.convertLink('rs', url_niz[3]),
                    enlink: '/eng/' + this.convertLink('en', url_niz[3]),
                    currentUrl: window.location.href
                });
                break;
            }
        }
    }
    DugmeSRBKlik(e) {
        this.setState({
            lang: 'rs'
        });
    }
    DugmeENGKlik(e) {
        this.setState({
            lang: 'en'
        });
    }

    componentDidMount() {
        var url_niz = window.location.href.split('/');
        this.defineURL(url_niz);
        this.apdejt=true;
    }
    componentDidUpdate() {
        var url_niz = window.location.href.split('/');
        if (this.state.currentUrl !== window.location.href) {
            this.defineURL(url_niz);
            this.apdejt=true;
        }
    }

    handleResponse (data){
        window.console.log(data);
    }
    handleError(error){
        window.console.log(error);
    }
    render() {
        const srLang = this.state.lang === 'rs';
        const lokal =this.state.lang === 'rs'?'sr_RS':'en_US';
        const ruteri=this.apdejt?
            <FacebookProvider language={lokal} appId="230978157802389">                       
                <Switch>
                    <Route path='/' exact={true} component={Home} />
                    <Route path='/tatjana' component={Tanja} />
                    <Route path='/vladimir' component={Vlada} />
                    <Route path='/projekti' component={Projekti} />
                    <Route path='/kontakt' component={Kontakt} />
                    <Route path='/eng' exact={true} component={HomeEng} />
                    <Route path='/eng/tatyana' component={TanjaENG} />
                    <Route path='/eng/vladimir' component={VladaENG} />
                    <Route path='/eng/projects' component={ProjektiENG} />
                    <Route path='/eng/contact' component={KontaktENG} />
                </Switch>
            </FacebookProvider> 
            :
            ''
        ;
        return (
            <div id="main">
                <header>
                    <nav className="navbar navbar-expand-md navbar-light">
                        <NavLink className="navbar-brand" to={this.state.lang === 'rs' ? '/' : '/енг'}><img alt="logo" id="logo" src={logo} width="150" /></NavLink>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <Navig lang={this.state.lang} />
                        <div className="row justify-content-end">
                            <ul className="nav">
                                <li className="nav-item">
                                    {/* <NavLink className={`nav-link nav-lang ${srLang ? 'active-lang' : ''} `} to={this.state.srlink} onClick={(e) => this.DugmeSRBKlik(e)} >Ср</NavLink> */}
                                    <a className={`nav-link nav-lang ${srLang ? 'active-lang' : ''} `} href={this.state.srlink} onClick={(e) => this.DugmeSRBKlik(e)} >Ср</a>
                                </li>
                                <li className="nav-item">
                                    {/* <NavLink className={`nav-link nav-lang ${srLang ? '' : 'active-lang'} `} to={this.state.enlink} onClick={(e) => this.DugmeENGKlik(e)} >En</NavLink> */}
                                    <a className={`nav-link nav-lang ${srLang ? '' : 'active-lang'} `} href={this.state.enlink} onClick={(e) => this.DugmeENGKlik(e)} >En</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                <div className='sadrzaj'>
                    <div className='row pt-4'>
                        <div className='col-6 col-lg-3 order-1 pr-0'>
                            <VladaBIO lang={this.state.lang}>
                            </VladaBIO>
                            <div className='infoDesk'>
                                <VladaLinks lang={this.state.lang}>
                                </VladaLinks>
                            </div>
                            <div className='infoDesk'>
                                <VladaInfo lang={this.state.lang}>
                                </VladaInfo>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 mx-auto order-3 order-lg-2'>                         
                            {ruteri}
                        </div>
                        <div className='col-6 col-lg-3 order-2 oreder-lg-3 ml-0 pl-0'>
                            <TanjaBIO lang={this.state.lang}>
                            </TanjaBIO>
                            <div className='infoDesk'>
                                <TanjaLinks lang={this.state.lang}>
                                </TanjaLinks>
                            </div>
                            <div className='infoDesk'>
                                <TanjaInfo lang={this.state.lang}>
                                </TanjaInfo>
                            </div>
                        </div>
                    </div>
                    <div className='row infoResponsive'>
                        <div className='col-12 px-4'>
                            <VladaLinks lang={this.state.lang}>
                            </VladaLinks>
                        </div>
                        <div className='col-12 px-4'>
                            <TanjaLinks lang={this.state.lang}>
                            </TanjaLinks>
                        </div>
                        <div className='col-12 px-4'>
                            <VladaInfo lang={this.state.lang}>
                            </VladaInfo>
                        </div>
                        <div className='col-12 px-4'>
                            <TanjaInfo lang={this.state.lang}>
                            </TanjaInfo>
                        </div>
                    </div>
                </div>
                <footer className="row pt-3 mx-5 mt-4 pb-lg-0">
                    <p className='col-11 mx-2 mx-lg-4'>
                        copyright &copy; Сокоћало <i>by Willy Wolf</i> | <a href="mailto:milivoyevich@gmail.com">milivoyevich@gmail.com</a> | <a href="http://validator.w3.org/check?uri=referer">HTML 5</a> | <a href="http://jigsaw.w3.org/css-validator/check/referer">CSS</a> | design by Willy Wolf
                    </p>
                </footer>
            </div >

        );
    }
}

export default Site;
/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

class Vlada extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Владимир</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate() {
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Владимир</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render() {
        return (
            <div className="col-12 mx-auto">
                <h1 className="text-center mt-5 mt-lg-0">ВЛАДИМИР МИЛИВОЈЕВИЋ</h1>
                <p className="text-justify pt-3 mx-3">Инжењер сам електротехнике за рачунарску технику, по звању. По занимању сам програмер, и бавим се израдом веб интранет и десктоп апликација, веб сајтова и система база података различитих намена.</p>
                <p className="text-justify pt-3 mx-3"> Највише радим у окружењу програмских језика C#, JavaScript, PHP као и Delphi (Pascal). Од база података највише користим MS SQL Server, MySQL, MongoDB и за потребе неких раније реализованих пројеката Access. У последње време све више се окрећем примени SQLite, смештању података у XML записе, раду са разним API-јима (FB, Google...) кроз Ajax и JSON. Све више се окрећем и Mobile програмирању и OnePage апликацијама, због практичних потреба за њихово укључивање у већ постојеће пројекте. За те потребе сам повремено радио неке мање пројекте у React-у, AngularJS-у, jQuery Mobile и .NET Core-у. Све више сам заинтересован за прављење PWA и ASP.NET Core апликација као универзалних решења за све OS.</p>
                <p className="text-justify pt-3 mx-3"> Свакодневно активно радим у Windows 10 OS, као и у неколико Линукс дистрибуција (Manjaro, OpenSuse, XUbuntu, Mint) користећи VirtualBox.</p>
            </div>
        );
    }
}

export default Vlada;
import React from 'react';

import OnePageENG from '../files/Tanja/CV-Tanja_1page_ENG.pdf';
import OnePageSRB from '../files/Tanja/CV-Tanja_1page_SRB.pdf';
import CvEuSRB from '../files/Tanja/CV-EU_Tanja_SRB.pdf';
import CvEuENG from '../files/Tanja/CV-EU_Tanja_ENG.pdf';

const TanjaInfo = (props) => {
    const ime = props.lang === 'rs' ? 'ТАТЈАНА - ПОДАЦИ' : 'TATYANA - INFO';
    const tekst = props.lang === 'rs' ?
        <p>Рођена у Бризбејну, Аустралија, 8. децембра 1971.</p>
        :
        <p>Born in Brisbane, Australia,
    8th December 1971</p>;
    const linkovi = props.lang === 'rs' ?
        <p>
            <a target='_blank' rel="noopener noreferrer" href={OnePageSRB} className="">CV српски One Page</a><br />
            <a target='_blank' rel="noopener noreferrer" href={CvEuSRB} className="">CV српски EU</a>
        </p>
        :
        <p>
            <a target='_blank' rel="noopener noreferrer" href={OnePageENG} className="">CV English One Page</a><br />
            <a target='_blank' rel="noopener noreferrer" href={CvEuENG} className="">CV English EU</a>
        </p>;
    return (
        <div className='side text-left order-5 order-lg-5 mr-lg-3 pl-2 ml-lg-0'>
            <p className='text-center'>{ime}</p>
            {tekst}
            {linkovi}
        </div>
    );
};

export default TanjaInfo;
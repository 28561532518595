import React from 'react';

const TanjaLinks = (props) => {
    const ime = props.lang === 'rs' ? 'ТАТЈАНИНИ ЛИНКОВИ' : 'TATYANA`S LINKS';
    return (
        <div className='side text-center order-2 order-lg-3  mr-lg-3 ml-lg-0'>
            <p>{ime}</p>
            <div className='text-left pl-2 mb-3'>
                <a target='_blank' rel="noopener noreferrer" href='http://www.linkedin.com/in/tatjanamilivojevic/' className="">LinkedIn</a><br />
                <a target='_blank' rel="noopener noreferrer" href='http://www.facebook.com/nikolicmilivojevic/' className="">Facebook</a><br />
            </div>
        </div>
    );
};

export default TanjaLinks;
/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

class Tanja extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Татјана</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate() {
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Татјана</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render() {
        return (
            <div className="col-12 mx-auto col-md-8 offset-md-4 col-lg-12 offset-lg-0">
                <h1 className="text-center mt-5 mt-lg-0">ТАТЈАНА ДРАГИЊА МИЛИВОЈЕВИЋ</h1>
                <p className="text-justify pt-3 mx-3">Поседујем вишегодишње искуство у процесу продаје од првог контакта са купцима до припреме и контроле комплетне документације за главног књиговођу, а радила сам и као књиговођа. Остваривала сам и неопходне контакте са шпедитерима, добављачима у земљи као и у иностранству; написала сам велик број пословних писама на српском и енглеском језику. Тако сам у свом досадашњем раду стекла драгоцено искуство на пословима малопродаје, велепродаје, набавке, комерцијале, администрације, књиговодства, инокореспонденције и превођења као и на секретарским пословима.
                </p><p className='text-justify mx-3'>
                    У раду сам веома темељна и прецизна. Свој перфекционизам успешно балансирам са великом ефикасношћу. Веома сам предузимљива, одликујем се великом самоиницијативом и веома сам мотивисана за рад. Спремна сам за рад под притиском. То ме све чини веома динамичном особом.
                </p><p className='text-justify mx-3'>
                    Имам велику способност прилагођавања свим тренутним ситуацијама, приликама и окружењу. Имам и искуства у опхођењу са људима најразличитијих профила. Асертивна сам особа.
                </p><p className='text-justify mx-3'>
                    У свом раду сагледавам краткорочне и дугорочне циљеве, одређујем приоритете и усклађујем свој рад у складу са њима и са датим околностима.
                </p><p className='text-justify mx-3'>
                    Увек сам спремна за учење и савладавање нових знања и вештина, а све у циљу максималне реализације послова уз апсолутно поштовање свих рокова, као и што квалитетнијег и ефикаснијег извршавања свих радних задатака.</p>
            </div>
        );
    }
}

export default Tanja;
// npm install --save raf
// npm install --save core-js 
// komktibilnost sa ie9+

/*jshint esversion: 6 */
import 'core-js/es';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import 'es6-shim';
import 'whatwg-fetch';

// import 'html5shiv';
// import 'html5shiv/dist/html5shiv-printshiv';
// import 'es5-shim';
// import 'es5-shim/es5-sham';

import './css/bootstrap.min.css';
//import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import {
    BrowserRouter
} from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import Site from './site.jsx';
import registerServiceWorker from './registerServiceWorker';

// specifying your onload callback function
var callback = function () {
    window.console.log('Done!!!!');
};

// specifying verify callback function
var verifyCallback = function (response) {
    window.console.log(response);
};

ReactDOM.render(
    <Helmet>
        <html lang="rs" />
        <title>Миливојевић</title>
    </Helmet>
    , document.getElementById('langTag'));
ReactDOM.render(
    <Recaptcha sitekey="6Ld4KIcUAAAAAEOi9Qc7UbgpFrHmwWBwUgJ1ek3x" 
        render="explicit"
        verifyCallback={verifyCallback}
        onloadCallback={callback}
    />,
    document.getElementById('catchaTag')
);
ReactDOM.render(
    <BrowserRouter> 
        <Site />  
    </BrowserRouter>
    , document.getElementById('root'));
registerServiceWorker();

// $(window).on('load',function () {
//     $('.nav-lang').bind('click', function (event) {
//         //event.preventDefault();
//         var clickedItem = $(this);
//         $('.nav-lang').each(function () {
//             $(this).removeClass('active');
//         });
//         clickedItem.addClass('active');
//     });
//     $('.nav-main').bind('click', function (event) {
//         //event.preventDefault();
//         var clickedItem = $(this);
//         $('.nav-main').each(function () {
//             $(this).removeClass('active');
//         });
//         clickedItem.addClass('active');
//     });
// });
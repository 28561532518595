import React from 'react';

const VladaLinks = (props) => {
    const ime = props.lang === 'rs' ? 'ВЛАДИМИРОВИ ЛИНКОВИ' : 'VLADIMIR`S LINKS';
    return (
        <div className='side text-center order-1 order-md-1 ml-lg-3 ml-lg-0'>
            <p>{ime}</p>
            <div className='text-left pl-2 mb-3'>
                <a target='_blank' rel="noopener noreferrer" href='http://www.linkedin.com/in/milivoyevich/' className="">LinkedIn</a><br />
                <a target='_blank' rel="noopener noreferrer" href='https://www.facebook.com/milivoyevich/' className="">Facebook</a><br />
                <a target='_blank' rel="noopener noreferrer" href='https://github.com/milivoyevich/' className="">Github</a><br />
            </div>
        </div>
    );

};

export default VladaLinks;
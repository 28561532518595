/* eslint-disable linebreak-style */
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

class VladaENG extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Vladimir</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate(){
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Vladimir</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render() {
        return (
            <div className="col-12mx-auto">
                <h1 className="text-center mt-5 mt-lg-0">VLADIMIR MILIVOYEVICH</h1>
                <p className="text-justify pt-3 mx-3">I am a computer engineer, i.e. programmer. I create web intranet and desktop applications, websites and database systems for different purposes.</p>
                <p className="text-justify pt-3 mx-3"> I mostly work in the environment of the programming languages C#, JavaScript, PHP, as well as Delphi (Pascal). When speaking of databases, I most often use MS SQL Server, MySQL, MongoDB and for some previously realized projects I use Access. Lately, I am more and more turning towards the usage of SQLite and storing data in XML files, working with various API’s (FB, Google…) through Ajax and JSON. I am more and more turning towards Mobile programming and OnePage applications, because of the practical needs for their inclusion into already existing projects. For those needs, I have occasionally made some smaller projects using React, AngularJS, jQuery Mobile and .NET Core. I am more and more interested in making PWA and ASP.NET Core applications as universal solutions for all OSs.</p>
                <p className="text-justify pt-3 mx-3"> I actively use Windows 10 OS every day, as well as a few Linux distributions (Manjaro, OpenSuse, XUbuntu, Mint) using VirtualBox.</p>
            </div>
        );
    }
}

export default VladaENG;
/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

class TanjaENG extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Tatyana</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate() {
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Tatyana</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render() {
        return (
            <div className="col-12 mx-auto col-md-8 offset-md-4 col-lg-12 offset-lg-0">
                <h1 className="text-center mt-5 mt-lg-0">TATYANA DRAGINYA MILIVOYEVICH</h1>
                <p className="text-justify pt-3 mx-3">I have many years of experience in working in sales, from the first contact with customers till the preparation and checking of the complete documentation for the chief accountant, and I have worked as an accountant. I have made the necessary contacts with forwarders, domestic and foreign suppliers; I have written a great number of formal, business letters in Serbian and English. In that way, I have acquired valuable experience in working in retail and wholesale trade, acquisition, sales, administration and accountancy as well as in secretarial work.
                </p><p className='text-justify mx-3'>
                    I am very thorough and precise in my work. I balance my perfectionism well with great efficiency. I am also proactive and self-motivated. I have great initiative and am willing to work under pressure. This all makes me a very dynamic person.
                </p><p className='text-justify mx-3'>
                    I am very flexible to current situations, conditions and environments. I have experience in interacting with people of various profiles. I am an assertive person.
                </p><p className='text-justify mx-3'>
                    While working, I survey the short-term and long-term objectives, determine the priorities and adjust my work according to them and to the given circumstances.
                </p><p className='text-justify mx-3'>
                    I am always ready to learn and master new knowledge and skills, in order to maximally realize jobs with absolute respect to all the deadlines, and to fulfill all tasks with high quality and most efficiently.</p>
            </div>
        );
    }
}

export default TanjaENG;
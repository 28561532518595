/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import KontaktForma from '../elementi/kontaktForma';
class KontaktENG extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Contact</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate() {
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Contact</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render() {
        return (
            <div className="col-12 mx-auto col-md-8 offset-md-4 col-lg-12 offset-lg-0">
                <h1 className="text-center mt-5 mt-lg-0">CONTACT</h1>
                <p className='pt-3 mx-3'>
                    You can contact us on the following e-mails:<br />
                    <a href='mailto:milivoyevich@gmail.com'>milivoyevich@gmail.com</a> (recommended address)<br />
                    <a href='mailto:milivoyevich@yahoo.com'>milivoyevich@yahoo.com</a><br />
                    <a href='mailto:draginya.tatyana@hotmail.com'>draginya.tatyana@hotmail.com</a><br />
                    as well as the phone number: <a href='tel:+381642113258'>+381(0)64/211-3258</a>.
                </p>            
                <KontaktForma lang='en'>
                </KontaktForma>             
            </div>
        );
    }
}
export default KontaktENG;
/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import MiSlika from '../imgs/mi_2018avg.jpg';

class HomeEng extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Welcome</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate() {
        ReactDOM.render(
            <Helmet>
                <html lang="en" />
                <title>The Milivoyevichs: Welcome</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render() {
        return (
            <div className="col-12 mx-auto">
                <h1 className="text-center mt-5 mt-lg-0">WELCOME</h1>
                <p className="text-justify pt-3 mx-3">You are visiting the website of the family Milivoyevich. This is a temporary version of our site. Its goal is to show and review our potentials and achievements. The site is done under the brand name Сокоћало by Willy Wolf for which we hope that will grow up into a small family company. We will show you the history of our work, which we have done and which we are doing, as well as some realized projects. We shall share some programs and ideas with you in oder to exchange knowledge and for the general welfare.</p>
                <img className='mx-3 mislika' width='95%' alt='We, avg 2018' src={MiSlika} />
            </div>
        );
    }
}

export default HomeEng;
/* eslint-disable linebreak-style */
import React, {Component}  from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Like } from 'react-facebook';

import Imenik from '../files/Imenik.zip';

class Projekti extends Component {
    componentDidMount(){
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Пројекти</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    componentDidUpdate(){
        ReactDOM.render(
            <Helmet>
                <html lang="rs" />
                <title>Миливојевић: Пројекти</title>
            </Helmet>
            , document.getElementById('langTag'));
    }
    render(){
        return (           
            <div className="col-12 mx-auto col-md-8 offset-md-4 col-lg-12 offset-lg-0">
                <div className="col-12 mx-auto"> 
                    <h1 className="text-center mt-5 mt-lg-0">ПРОЈЕКТИ</h1>
                    <p id='WWImenik' className='pt-3 mx-3 font-weight-bold'>WW Именик .NET</p>
                    <p>Врло функционалан адресар за свакодневну примену. Програм можете преузети на <a href={Imenik}>следећој локацији</a>. Потребно је да претходно имате инсталиран .NET 4.0. <a target='_blank' rel="noopener noreferrer" href='https://milivoyevich.visualstudio.com/Adresar'>Више детаља...</a></p>
                    <Like width='280' href="http://миливојевић.срб/Пројекти/#WWImenik" colorScheme="dark" showFaces share />  
                </div> 
            </div>         
        );
    }
}
export default Projekti;